import React from "react"
import { graphql } from "gatsby"
import "queries/postTemplateQuery"
import Layout from "components/Layout/Layout"
import PostLayout from "components/PostLayout/PostLayout"

const PostTemplate = (props) =>  {
	const data = props.data.wpPost
    var imageUrl = [];
	const title = data.seo.title || data.title
	const description = data.seo.metaDesc || data.excerpt
	const googleAds = data.acfGoogleAds
	const googleAdsSidebar = data.acfGoogleAdsSidebar

    //do we have an image for sharing
	if (data.featuredImage) {
        imageUrl = data.featuredImage.node.sourceUrl
	}
	
    return (
        <Layout data={data} title={title} description={description} image={imageUrl} url={data.link} path={`/news${data.uri}`} googleAds={googleAds}>
            <PostLayout data={data} googleAdsSidebar={googleAdsSidebar} />
        </Layout>
    )
}

export default PostTemplate

export const pageQuery = graphql`
query($id: String!) {
  wpPost(id: {eq: $id}) {
		link
		uri
		slug
		title
		excerpt
		date
		featuredImage {
      node {
        altText
        sourceUrl
        localFile {
          childImageSharp {
            gatsbyImageData(width: 768, quality: 90, placeholder: NONE, layout: CONSTRAINED)
          }
        }
      }
		}
		seo {
			title
			metaDesc
		}
		acfGoogleAds {
			bellybandName
		}
		acfGoogleAdsSidebar {
			sidebarMpus {
				type
				name
			}
		}
		...postTemplateQuery
  }
}`